@import '../../styles/styleguide.scss';

.content {
  background-color: rgba($blue, 0.72);
  bottom: 24px;
  padding: 10px 20px;
  position: absolute;

  @include medium-up {
    bottom: 44px;
  }

  @include large-up {
    bottom: 115px;
    padding: 26px 53px;
  }
}

.hero {
  background-color: $blue--dark;
  position: relative;
}

.h1 {
  @include h1;

  color: $white;
  font-size: 22px;
  margin: 0;
  @include medium-up {
    font-size: 36px;
  }
  
  @include large-up {
    font-size: 60px;
  }
}
