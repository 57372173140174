@import '../../styles/styleguide.scss';

.image {
  border-radius: 6px;
  height: auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}
