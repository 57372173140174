@import '../../styles/styleguide.scss';

.bottomRight {
  display: flex;
  flex-direction: column;
}

.bottomRightInner {
  display: flex;
  gap: $spaceXS;

  @include medium-up {
    justify-content: space-between;
    gap: 0;
  }
}

.link {
  color: $white;
  font-weight: 800;
  text-decoration: underline;
}

.right {
  display: flex;
  flex-direction: column;
  gap: $spaceXS;
  justify-content: space-between;
}
