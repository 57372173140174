@import '../../styles/styleguide.scss';

.innerContentFixedSidebar {
  @include large-up {
    display: flex;
  }
}

.ribbonDetail {
  display: none;

  @include large-up {
    display: block;
  }
}
