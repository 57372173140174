@import '../../../styles/styleguide.scss';

.btn {
  align-items: center;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  color: $white;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 0;
  width: 30px;

  @include medium-up {
    border-radius: 24px;
    height: 48px;
    width: 48px;
  }
}

.btnlight {
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.btndark {
  border: 2px solid rgba(39, 109, 147, 0.5);
}

.btn:before {
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 16px;
  width: 16px;
}

.btnlight:before {
  background-image: url('../../../public/icon-arrow-right.png');
}

.btndark:before {
  background-image: url('../../../public/icon-arrow-right--dark.png');
}
