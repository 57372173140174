@import '../../../styles/styleguide.scss';

.button {
  align-items: center;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;

  @include large-up {
    height: 38px;
    width: 38px;
  }
}
