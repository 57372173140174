@import '../../styles/styleguide.scss';

.item {
  grid-column: 2;
  grid-row: 1;
  margin: 0;
  padding: 0;

  blockquote {
    color: $white;
  }

  figcaption {
    color: $white;
  }
}

.itemdark {
  blockquote {
    color: $blue--dark;
  }
  
  figcaption {
    color: $blue--dark;
  }
}

.itemlight {
  color: $white;
}

.itemEnter {
  opacity: 0;
}

.itemEnterActive {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.itemExit {
  opacity: 1;
}

.itemExitActive {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.list {
  align-items: center;
  display: grid;
  grid-column-gap: $spaceXS;
  grid-template-columns: $spaceSmall 1fr $spaceSmall;
  position: relative;

  @include medium-up {
    grid-column-gap: $spaceSmall;
    grid-template-columns: 48px 1fr 48px;
  }
}
