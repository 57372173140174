@import '../../styles/styleguide.scss';

.body {
  color: $white;
  line-height: 1.25;
}

.bold {
  font-weight: bold;
  margin-top: 10px;

  @include large-up {
    margin-top: 16px;
  }
}

.fill {
  flex: 1;
  width: 100%;
}

.logoWrapper {
  height: 50px;
  margin-bottom: $spaceSmall;
  position: relative;
  width: 50px;

  @include large-up {
    height: 94px;
    margin-bottom: 0;
    margin-right: 40px;
    width: 94px;
  }
}

.right {
  @include medium-up {
    text-align: right;
    margin-left: auto;
  }
}

.section {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include medium-up {
    flex-direction: row;
  }
}

.social {
  display: block;
  height: 20px;
  margin-right: 20px;
  width: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.socialWrapper {
  align-items: center;
  display: flex;
  margin-bottom: 13px;
  margin-top: 24px;

  @include medium-up {
    justify-content: flex-end;
    margin-top: 0;
  }
}

.small {
  color: $white;
}

.title {
  color: $white;
  font-weight: bold;
}

.wrapper {
  align-items: flex-start;
  background-color: $blue--dark;
  display: flex;
  min-height: 409px;
  flex-direction: column;
  justify-content: center;
  padding: $spaceSmall;

  @include medium-up {
    min-height: 203px;
  }

  @include large-up {
    align-items: center;
    flex-direction: row;
    min-height: 213px;
    justify-content: flex-start;
    padding: $spaceMedium;
  }
}
