@import '../../styles/styleguide.scss';

$social-feature-height: 300px;

.content {
  height: 100%;
  max-width: 1440px;
  min-height: $social-feature-height;
}

.left {
  position: relative;
  margin-right: 0 !important;
  min-height: $social-feature-height;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spaceSmall $spaceMedium;
  text-align: center;

  @include large-up {
    padding: 0 $spaceSmall;
  }
}

.socialIcons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.text {
  color: $blue--dark !important;
}

.title {
  color: $blue--dark !important;
}

.wrapper { 
  min-height: $social-feature-height;
  padding: 0;
}
