@import '../../styles/styleguide.scss';

.showcase {
  background-color: $blue;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.primary {
  @include large-up {
    flex: 2;
  }
}

.secondary {
  @include large-up {
    flex: 1;
  }
}

.content {
  margin: 0 auto;
  max-width: $max-width;

  @include large-up {
    display: flex;
  }
}

.content > div {
  margin-bottom: $spaceSmall;

  @include large-up {
    margin-bottom: 0;
    margin-right: $spaceLarge;
  }

  @include xl-up {
    margin-right: $spaceXL;
  }
}

.content > div:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.videoContainer {
  overflow: hidden;
  padding-top: 56.25%; /* 16:9*/
  position: relative;
}

.imageContainer {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  max-width: 100%;
}

.showcase iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.showcase h2 {
  @include h2;

  color: $white;
}

.showcase p {
  @include body;

  color: $white;
}
