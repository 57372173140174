@import '../../styles/styleguide.scss';

.background {
  background-image: url('../../public/testimonial-carousel-s.jpg');
  background-position: center;
  background-size: cover;

  @include medium-up {
    background-image: url('../../public/testimonial-carousel-m.png');
  }

  @include large-up {
    background-image: url('../../public/testimonial-carousel-l.png');
  }

  @include xl-up {
    background-image: url('../../public/testimonial-carousel-xl.png');
  }
}
