@import '../../styles/styleguide.scss';

.wrapper {
  align-items: center;
  background-color: $blue--dark;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  padding-right: 16px;

  @include medium-up {
    height: 40px;
  }

  @include large-up {
    padding-right: 26px;
  }

  @include xl-up {
    padding-right: 46px;
  }
}

.item {
  @include small;

  color: $white;
  margin-left: 16px;

  strong {
    margin-left: 5px;
  }

  @include medium-up {
    margin-left: 50px;

    strong {
      margin-left: 14px;
    }
  }

  @include large-up {
    font-size: 16px;
  }
  @include xl-up {
    strong {
      margin-left: 20px;
    }
  }
}
